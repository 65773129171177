import { useState } from 'react';
import { useInterval } from 'usehooks-ts';

const addZeroToTime = (number: number) => (number < 10 ? `0${number}` : number);

const useTime = () => {
  const [time, setTime] = useState(new Date());
  const hours = addZeroToTime(time.getHours());
  const minutes = addZeroToTime(time.getMinutes());
  const seconds = addZeroToTime(time.getSeconds());

  useInterval(() => {
    setTime(new Date());
  }, 1000);

  return {
    models: {
      currentTime: `${hours}:${minutes}:${seconds}`,
    },
  };
};

export default useTime;
