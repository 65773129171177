import React from 'react';

export const LogoBold = (
  props: JSX.IntrinsicAttributes &
  React.ClassAttributes<HTMLSpanElement> &
  React.HTMLAttributes<HTMLSpanElement>,
) => {
  const { className, ...rest } = props;
  return (
    <span className={className} {...rest}>
      <svg width='205' height='72' viewBox='0 0 205 72' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
        <path d='M23.6021 20.4561V40.9209C23.5978 42.4351 23.436 43.9449 23.1194 45.4257C22.8107 46.9009 22.2029 48.2972 21.3336 49.5283C20.4017 50.7992 19.1763 51.8258 17.7619 52.5208C15.8823 53.3709 13.8301 53.7703 11.7689 53.6872C10.3709 53.6966 8.98035 53.482 7.65017 53.0517C6.39768 52.6537 5.21052 52.0736 4.12675 51.3302C3.10413 50.6214 2.20004 49.7553 1.44798 48.7641C0.810777 47.9312 0.321029 46.9952 0 45.9968L8.71202 42.6665C8.86566 43.0346 9.07157 43.3787 9.32339 43.6881C9.53051 43.934 9.79114 44.1293 10.0853 44.2589C10.3795 44.3886 10.6995 44.4493 11.0207 44.4362C11.3903 44.4708 11.7619 44.3972 12.0904 44.2244C12.4189 44.0516 12.6901 43.787 12.8709 43.4629C13.174 42.8141 13.3201 42.1031 13.2973 41.3874V20.4721L23.6021 20.4561Z' fill='currentColor' />
        <path d='M52.3447 35.3703C51.6491 33.8337 50.6345 32.4626 49.3682 31.3482C48.0949 30.2686 46.6293 29.4389 45.0484 28.9027C41.6728 27.7766 38.0228 27.7766 34.6471 28.9027C33.0596 29.4355 31.5919 30.2746 30.3273 31.3723C29.0683 32.4896 28.0596 33.8603 27.367 35.3945C26.6073 37.0968 26.2285 38.9444 26.2569 40.8083C26.2288 42.6835 26.6018 44.5431 27.3509 46.2624C28.0569 47.7887 29.0638 49.1567 30.3112 50.2845C31.5635 51.3861 33.0166 52.2355 34.5908 52.7863C37.978 53.9595 41.6613 53.9595 45.0484 52.7863C46.6494 52.2373 48.1297 51.3852 49.4085 50.2765C50.6707 49.1585 51.6847 47.7883 52.3849 46.2543C53.1477 44.5306 53.5239 42.6607 53.4869 40.7761C53.5093 38.912 53.1192 37.066 52.3447 35.3703ZM43.1017 44.0984C42.6782 44.5339 42.1716 44.88 41.612 45.1163C41.0525 45.3526 40.4512 45.4744 39.8437 45.4744C39.2363 45.4744 38.635 45.3526 38.0754 45.1163C37.5159 44.88 37.0093 44.5339 36.5858 44.0984C35.7584 43.2119 35.3109 42.0367 35.3389 40.8244C35.3248 39.6502 35.7626 38.5154 36.5617 37.6549C36.9648 37.2043 37.4619 36.8476 38.0178 36.6099C38.5738 36.3723 39.1751 36.2594 39.7794 36.2793C40.3912 36.2594 41.0002 36.3711 41.5651 36.6071C42.13 36.843 42.6376 37.1976 43.0534 37.6469C43.8823 38.4958 44.342 39.638 44.3325 40.8244C44.3606 42.0338 43.9195 43.2071 43.1017 44.0984Z' fill='currentColor' />
        <path d='M82.8553 36.7467C82.425 35.3285 81.7438 34.0005 80.8447 32.8268C79.9624 31.6698 78.8425 30.7189 77.5616 30.0392C76.3613 29.4046 75.0359 29.0476 73.6817 28.9939H73.5639H73.0298C71.8973 29.0033 70.7763 29.2234 69.7232 29.6433C69.2244 29.8331 68.7476 30.0774 68.3016 30.3718H68.2544C67.8821 30.6369 67.5374 30.9394 67.2255 31.2746C67.1871 31.3102 67.1529 31.35 67.1234 31.3934L66.9821 31.5597C66.7054 31.8404 66.4507 32.1422 66.2202 32.4625V19H56.5518V53.0522H65.1913V50.4705V49.4094C65.4325 49.7781 65.6948 50.1324 65.9767 50.4705L66.1103 50.6685C66.4447 51.1218 66.8444 51.5222 67.2962 51.8564C67.801 52.2251 68.3466 52.5334 68.922 52.775C69.1655 52.8779 69.4169 52.9651 69.6525 53.0522C70.0724 53.1973 70.5031 53.3085 70.9406 53.3848C71.6154 53.5219 72.3021 53.5908 72.9905 53.5907C73.2732 53.5907 73.5639 53.5907 73.8466 53.5511C75.1274 53.4778 76.3779 53.1295 77.5145 52.5295C78.7974 51.8495 79.9178 50.8955 80.7975 49.7341C81.6941 48.5441 82.3748 47.2036 82.8082 45.7745C83.2653 44.2899 83.4957 42.7438 83.4915 41.1893C83.5068 39.6843 83.2922 38.1859 82.8553 36.7467ZM70.2887 45.8062C69.6709 45.8304 69.0551 45.723 68.4813 45.491C67.9074 45.259 67.3886 44.9077 66.9585 44.4599C66.1388 43.5893 65.6904 42.4292 65.7097 41.2289C65.6934 40.048 66.1425 38.9087 66.9585 38.0613C67.3877 37.6123 67.9064 37.2602 68.4804 37.0281C69.0544 36.7961 69.6707 36.6894 70.2887 36.715C70.9031 36.6811 71.5174 36.7858 72.0866 37.0214C72.6559 37.2571 73.1659 37.6177 73.5796 38.0771C74.2898 38.9678 74.6579 40.0868 74.6163 41.2289C74.6542 42.4091 74.26 43.562 73.5089 44.4679C73.0961 44.9183 72.5902 45.2717 72.0269 45.5029C71.4636 45.7342 70.8568 45.8377 70.2494 45.8062H70.2887Z' fill='currentColor' />
        <path d='M116.731 28.7096C115.733 26.7636 114.279 25.0883 112.492 23.8267C110.628 22.559 108.531 21.6732 106.322 21.2204C103.875 20.7068 101.381 20.4506 98.881 20.4561H86.9673V52.7863H99.8382C102.207 52.7944 104.566 52.4695 106.845 51.821C108.983 51.2276 110.985 50.225 112.741 48.8688C114.468 47.513 115.86 45.7792 116.812 43.8008C117.826 41.4197 118.343 38.8567 118.332 36.2687C118.321 33.6807 117.782 31.1222 116.747 28.7499L116.731 28.7096ZM105.646 42.2483C104.174 43.4469 102.026 44.0582 99.3073 44.0582H96.7733V28.9912H99.7497C101.896 28.8899 104.007 29.5733 105.686 30.9138C106.446 31.5822 107.045 32.4131 107.439 33.3448C107.833 34.2765 108.012 35.285 107.963 36.2955C107.939 39.0225 107.175 41.0255 105.646 42.2483Z' fill='currentColor' />
        <path d='M146.632 35.3703C145.943 33.8323 144.93 32.4606 143.664 31.3481C142.383 30.2564 140.906 29.4183 139.312 28.8785C135.936 27.7524 132.286 27.7524 128.911 28.8785C127.322 29.4094 125.854 30.2487 124.591 31.3481C123.33 32.4633 122.32 33.8345 121.631 35.3703C120.867 37.0714 120.488 38.9199 120.52 40.7841C120.49 42.669 120.869 44.538 121.631 46.2623C122.328 47.7935 123.336 49.1631 124.591 50.2845C125.853 51.3939 127.321 52.244 128.911 52.7863C132.278 53.9447 135.936 53.9447 139.304 52.7863C140.905 52.2373 142.385 51.3852 143.664 50.2765C144.926 49.1599 145.937 47.7892 146.632 46.2543C147.398 44.5312 147.777 42.6613 147.743 40.7761C147.776 38.9143 147.397 37.0683 146.632 35.3703ZM137.397 44.0984C136.974 44.5338 136.467 44.8799 135.908 45.1162C135.348 45.3526 134.747 45.4744 134.14 45.4744C133.532 45.4744 132.931 45.3526 132.371 45.1162C131.812 44.8799 131.305 44.5338 130.882 44.0984C130.09 43.2135 129.652 42.0679 129.652 40.8807C129.652 39.6934 130.09 38.5478 130.882 37.6629C131.286 37.2136 131.783 36.8578 132.339 36.6202C132.894 36.3827 133.495 36.2691 134.099 36.2873C134.711 36.2695 135.319 36.3822 135.884 36.618C136.448 36.8538 136.956 37.2073 137.373 37.6549C138.202 38.5038 138.662 39.646 138.652 40.8324C138.672 42.0423 138.222 43.2128 137.397 44.0984Z' fill='currentColor' />
        <path d='M176.38 38.1938V52.7862H166.47V40.0762C166.558 39.0584 166.322 38.0388 165.794 37.1641C165.536 36.8505 165.208 36.6019 164.836 36.4384C164.465 36.2749 164.06 36.2011 163.654 36.2229C163.241 36.2142 162.833 36.3055 162.463 36.489C162.093 36.6725 161.773 36.9428 161.531 37.2767C161.156 37.7762 160.915 38.3633 160.831 38.9821C160.771 39.3437 160.741 39.7097 160.742 40.0762V52.7862H150.848V28.9911H160.501V33.2385C160.709 32.7022 160.979 32.192 161.305 31.7181L161.394 31.5974L161.442 31.525C162 30.7472 162.716 30.095 163.542 29.6105C163.711 29.4979 163.888 29.3933 164.065 29.2968C165.303 28.6437 166.686 28.3118 168.087 28.3314C169.48 28.291 170.861 28.595 172.109 29.2163C173.122 29.7696 173.995 30.5485 174.659 31.4929C175.299 32.4475 175.752 33.5148 175.994 34.6382C176.245 35.8069 176.375 36.9984 176.38 38.1938Z' fill='currentColor' />
        <path d='M204.189 35.8932C203.632 34.3524 202.764 32.9424 201.639 31.7504C200.523 30.581 199.175 29.6577 197.682 29.0395C196.058 28.3764 194.319 28.0454 192.565 28.0661C190.79 28.054 189.027 28.3619 187.361 28.9751C185.798 29.5405 184.365 30.4131 183.145 31.5412C181.93 32.6666 180.967 34.0376 180.322 35.5634C179.615 37.2488 179.262 39.0614 179.284 40.8888C179.244 42.8073 179.629 44.711 180.41 46.4635C181.112 48.0032 182.135 49.3747 183.411 50.4857C184.688 51.5606 186.16 52.3799 187.747 52.899C191.714 54.1719 196.011 53.9341 199.813 52.2313C201.533 51.4035 203.014 50.1523 204.117 48.5952L197.207 45.104C196.788 45.5162 196.308 45.8608 195.783 46.1256C194.937 46.5471 194.001 46.7543 193.056 46.729C192.513 46.7303 191.972 46.66 191.447 46.5198C190.933 46.3936 190.441 46.1873 189.991 45.9084C189.569 45.6356 189.196 45.2929 188.889 44.8949C188.565 44.4877 188.374 43.9905 188.342 43.471L188.302 42.9723H204.994C204.994 42.7953 204.994 42.5942 204.994 42.3689C204.994 41.9587 204.994 41.5645 204.994 41.1382C205.046 39.3555 204.773 37.5783 204.189 35.8932ZM196.7 38.0652H188.237L188.294 37.5343C188.491 36.5355 189.041 35.6409 189.843 35.0134C190.644 34.3858 191.645 34.0672 192.662 34.1154C193.718 34.0716 194.753 34.4149 195.574 35.0808C195.935 35.3899 196.224 35.7753 196.419 36.2093C196.613 36.6433 196.71 37.115 196.7 37.5906V38.0652Z' fill='currentColor' />
      </svg>
    </span>
  );
};
