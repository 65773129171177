import { assign, createMachine } from 'xstate';

export enum ConfirmMachineType {
  OPEN_DIALOG = 'OPEN_DIALOG',
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
  CHANGE = 'CHANGE',
}

export interface ConfirmationDialogMachineContext {
  action: () => Promise<unknown> | void
  errorMessage: string;
  onSuccess?: () => Promise<unknown> | void
}

type ConfirmationDialogMachineEvent =
  | {
    type: ConfirmMachineType.OPEN_DIALOG
    action: () => Promise<unknown> | void
    onSuccess?: () => Promise<unknown> | void
  }
  | {
    type: ConfirmMachineType.CHANGE
    action: () => Promise<unknown> | void
  }
  | {
    type: ConfirmMachineType.CONFIRM;
  }
  | {
    type: ConfirmMachineType.CANCEL;
  };

const confirmationDialogMachine = createMachine<
ConfirmationDialogMachineContext,
ConfirmationDialogMachineEvent
>(
  {
    id: 'confirmationDialog',
    initial: 'closed',
    states: {
      closed: {
        id: 'closed',
        on: {
          OPEN_DIALOG: {
            target: 'open',
            actions: 'assignActionToContext',
          },
        },
      },
      open: {
        exit: ['clearErrorMessage'],
        initial: 'idle',
        states: {
          idle: {
            on: {
              CANCEL: '#closed',
              CONFIRM: {
                target: 'executingAction',
                actions: 'clearErrorMessage',
              },
              CHANGE: {
                actions: 'assignActionToContext',
              },
            },
          },
          executingAction: {
            invoke: {
              src: 'executeAction',
              onError: {
                target: 'idle',
                actions: 'assignErrorMessageToContext',
              },
              onDone: {
                target: '#closed',
                actions: ['clearActionFromContext', 'onSuccess'],
              },
            },
          },
        },
      },
    },
  },
  {
    services: {
      executeAction: (context) => async () => context?.action(),
    },
    actions: {
      assignActionToContext: assign((_, event) => {
        if (event.type !== ConfirmMachineType.OPEN_DIALOG && event.type !== ConfirmMachineType.CHANGE) return {};
        return {
          action: event.action,
        };
      }),
      assignErrorMessageToContext: assign((_, event: any) => ({
        errorMessage: event.data?.message || 'An unknown error occurred',
      })),
      clearErrorMessage: assign((context) => ({
        errorMessage: undefined,
        action: context.action,
      })),
      clearActionFromContext: assign((context, event: any) => ({
        errorMessage: context.errorMessage || event.data?.message,
        action: undefined,
      })),
      onSuccess: (context) => async () => {
        if (context.onSuccess) {
          return context?.onSuccess();
        }
        return new Promise((resolve) => resolve('Success!'));
      },
    },
  },
);

export default confirmationDialogMachine;
