import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import dynamic from 'next/dynamic';
import { Button, Icons } from '@jobdone/jobdone-web-design-system';
import { useRouter } from 'next/router';
import useWindowSize from '@jobdone/jobdone-web-design-system/src/utils/useWindowSize';
import cx from 'classnames';
import AreYouSureToLogOut from '@components/AreYouSureToLogOut';
import { useTimeout } from 'usehooks-ts';
import Time from './components/Time';
import Logo from './components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 40,
    alignItems: 'center',
    padding: '0 10px',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  rootLandScape: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  logoutContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    paddingTop: 20,
    paddingRight: 30,
  },
  logoutContainerLandScape: {
    width: 'auto',
    paddingRight: 0,
    paddingTop: 0,
  },
  qrCodeAndTime: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const LiveQRContainer = () => {
  const classes = useStyles();
  const router = useRouter();
  const { height, width } = useWindowSize();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  /**
   * https://github.com/kozakdenys/qr-code-styling/issues/89#issuecomment-1025140152
   */
  const QRCode = dynamic(() => import('@modules/LiveQR/components/QRCode'), {
    ssr: false,
  });

  // reload the page after 6 hours
  useTimeout(() => router.reload(), 21600000);

  useEffect(() => {
    async function fetchData() {
      if (!localStorage.getItem('organizationId')) {
        await router.push('/select-organization');
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx(classes.root, {
      [classes.rootLandScape]: (width && height) && width > height * 1.4,
    })}
    >
      <div className={cx(classes.logoutContainer, {
        [classes.logoutContainerLandScape]: (width && height) && width > height * 1.4,
      })}
      >
        <Button
          variant='fixedIcon'
          icon={<Icons.Logout />}
          onClick={() => setIsDialogOpen(true)}
        />
      </div>
      <Logo />
      <div className={classes.qrCodeAndTime}>
        <QRCode />
        <Time />
      </div>
      <AreYouSureToLogOut
        isOpen={isDialogOpen}
        toggle={() => setIsDialogOpen((prev) => !prev)}
      />
    </div>
  );
};

export default LiveQRContainer;
