import React from 'react';
import { makeStyles } from '@material-ui/core';
import { LogoBold } from '../assets/images';

const useStyles = makeStyles((theme) => ({
  logo: {
    // marginTop: 64,
    // marginBottom: 8,
    color: theme.palette.common.white,
  },
}));

const Logo = () => <LogoBold className={useStyles().logo} />;

export default Logo;
