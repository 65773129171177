import confirmMachine, { ConfirmMachineType } from '@common/utils/confirmMachine';
import { ConfirmDialog } from '@jobdone/jobdone-web-design-system';
import { useMachine } from '@xstate/react';
import { signOut } from 'lib/userActions';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

interface Props {
  toggle: () => void
  isOpen: boolean
}

const AreYouSureToLogOut = ({ toggle, isOpen }: Props) => {
  const router = useRouter();
  const { t } = useTranslation(['staff', 'common', 'validations']);
  const [current, send] = useMachine(confirmMachine);

  const closeDialog = () => {
    send(ConfirmMachineType.CANCEL);
    toggle();
  };

  const confirmDialog = () => {
    send(ConfirmMachineType.CONFIRM);
  };

  useEffect(() => {
    const handleClickLogout = async () => {
      await signOut().then(() => router.push('/login'));
      localStorage.removeItem('organizationId');
      toggle();
    };
    if (isOpen) {
      send({
        type: ConfirmMachineType.OPEN_DIALOG,
        action: async () => handleClickLogout(),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <ConfirmDialog
      open={current.value !== 'closed'}
      handleClose={closeDialog}
      isLoading={current.event.type === ConfirmMachineType.CONFIRM}
      handleConfirm={confirmDialog}
      locale={{
        confirmButton: t('common:log_out'),
        content: t('common:are_you_sure_to_log_out'),
        declineButton: t('common:cancel'),
        title: t('common:stop_live_qr'),
        errorMessage: current.context.errorMessage && t(`validations:client.${current.context.errorMessage as string}`),
      }}
    />
  );
};

export default AreYouSureToLogOut;
