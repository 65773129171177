import React from 'react';
import { makeStyles } from '@material-ui/core';
import useTime from './useTime';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 40,
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  qr: {
    margin: '0 10px',
    width: 400,
    height: 400,
    '& > svg': {
      width: 400,
      height: 400,
      [theme.breakpoints.down('md')]: {
        width: 350,
        height: 350,
      },
      [theme.breakpoints.down('xs')]: {
        width: 295,
        height: 295,
      },
    },
  },
  logo: {
    marginTop: 64,
    marginBottom: 8,
    color: theme.palette.common.white,
  },
  time: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 60,
    padding: '0 5px',
    width: '100%',
    '@media screen and (max-height: 500px)': {
      fontSize: 40,
    },
  },
}));

const Time = () => {
  const classes = useStyles();
  const {
    models: { currentTime },
  } = useTime();

  return (
    <div className={classes.time}>
      {currentTime}
    </div>
  );
};

export default Time;
